import React from "react";
import { Modal } from "react-bootstrap";
import style from "./profile.module.css";
import secureLocalStorage from "react-secure-storage";
import * as assets from "../../assets/index";
import PropsTypes from "prop-types";
import { login, masterData } from "../../helpers/constants";
import CommonInput from "../../components/inputs";
const MyProfile = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
  };

  const profileInfo = JSON.parse(secureLocalStorage?.getItem("loginResponse"));

  const getInitials = (name) => {
    const words = name?.trim()?.split(" ");

    let firstInitial;
    let lastInitial;

    if (words?.length >= 2) {
      firstInitial = Array?.isArray(words) ? words[0]?.charAt(0) : "";
      lastInitial = words[words?.length - 1]?.charAt(0);
      return `${firstInitial}${lastInitial}`?.toUpperCase();
    } else {
      firstInitial = Array?.isArray(words) ? words[0]?.charAt(0) : "";
      return firstInitial?.toUpperCase();
    }
  };

  return (
    <Modal
      centered
      show={show}
      size="lg"
      className="profile_modal"
      backdrop="static"
      onHide={handleClose}
    >
      <img
        alt="closeIcon"
        src={assets.closeIcon}
        className={style.cross_icon}
        onClick={() => {
          setShow(false);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setShow(false);
          }
        }}
      />
      <Modal.Body className={style.modal}>
        <div className="row">
          <div className={`col-12 col-md-4 ${style.profile_info}`}>
            <div className={style.name_letter}>
              {getInitials(profileInfo?.userName)}
            </div>
            <div className={`${style.user_name} text-center`}>
              {profileInfo?.userName || ""}
            </div>
            <div className={`${style.user_role} text-center`}>
              {profileInfo?.roleName || ""}
            </div>
          </div>

          <div className="col-12 col-md-8">
            <div className={style.profile_data}>
              <h2 className="mb-4">Profile</h2>
              <div className="form-group mb-4">
                <CommonInput
                  label={login.userName}
                  styles={{ marginTop: "0" }}
                  type="text"
                  value={profileInfo?.userName || ""}
                  disabled={true}
                  errMsg={""}
                />
              </div>
              <div className="form-group mb-4">
                <CommonInput
                  label={masterData.mobileNumber}
                  styles={{ marginTop: "0" }}
                  type="tel"
                  value={
                    profileInfo?.mobileNumber
                      ? `+91 ${profileInfo?.mobileNumber}`
                      : ""
                  }
                  disabled={true}
                  errMsg={""}
                />
              </div>
              <div className="form-group mb-4">
                <CommonInput
                  label={login.OLMID}
                  styles={{ marginTop: "0" }}
                  type="text"
                  value={profileInfo?.olmId || ""}
                  disabled={true}
                  errMsg={""}
                />
              </div>
              <div className="form-group mb-4">
                <CommonInput
                  label={login.emailID}
                  styles={{ marginTop: "0" }}
                  type="email"
                  value={profileInfo?.email || ""}
                  disabled={true}
                  errMsg={""}
                />
              </div>
              {profileInfo?.circles?.length > 0 && (
                <div className="form-group mb-4">
                  <CommonInput
                    label={login.circle}
                    styles={{ marginTop: "0" }}
                    type="text"
                    value={profileInfo?.circles
                      ?.map((item) => item?.name)
                      ?.join(", ")}
                    disabled={true}
                    errMsg={""}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
MyProfile.propTypes = {
  show: PropsTypes.bool,
  setShow: PropsTypes.func,
};

export default MyProfile;
