import { useLocation } from "react-router";
import NavbarComponent from "./components/Header";
import Routing from "./routes/Routing";
import { useEffect } from "react";

const App = () => {
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/" ||
    location.pathname === "/page-not-found" ||
    location.pathname === "/server-error";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      {!isLoginPage && <NavbarComponent />}
      <Routing />
    </>
  );
};
export default App;
