import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, encryptUsingAES256 } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";
import ExcelFileDownload from "../../helpers/ExcelFileDownload";

const initialUserExcelDataState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  userManagementData: [],
};
const getUserExcelSlice = createSlice({
  name: "Get User Management Data",
  initialState: initialUserExcelDataState,
  reducers: {
    userExcelApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.userManagementData = [];
    },
    userExcelApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.userManagementData = action.payload;
    },
    userExcelApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.userManagementData = [];
    },
  },
});

export const getUserExcelDataAction = ({
  searchData,
  filterData,
  navigate,
}) => {
  let url = `/users-excel-data`;
  const addQueryParam = (paramName, paramValue) => {
    if (paramValue) {
      const paramToAppend = encryptUsingAES256(
        paramValue === "ACTIVE" || paramValue === "INACTIVE" || paramName === "searchKeyword"
          ? paramValue
          : JSON.stringify(paramValue)
      );

      if (url.includes("?")) {
        url += `&${paramName}=${paramToAppend}`;
      } else {
        url += `?${paramName}=${paramToAppend}`;
      }
    }
  };

  return (dispatch) => {
    dispatch(GetUserManagementActions.userExcelApiRequested());
    addQueryParam("searchKeyword", searchData);
    addQueryParam("teamId", filterData?.teamId);
    addQueryParam("roleId", filterData?.roleId);
    addQueryParam("circleId", filterData?.circleId);
    if (filterData?.statusName) {
      addQueryParam(
        "status",
        filterData?.statusName === "Active" ? "ACTIVE" : "INACTIVE"
      );
    }

    makeHttpRequest({ method: "get", url: url, navigate })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            GetUserManagementActions.userExcelApiSuccess(
              res?.data?.data?.userDetailsResponses
            )
          );
          ExcelFileDownload(res?.data?.data?.userDetailsResponses, "user");
        } else {
          dispatch(
            GetUserManagementActions.userExcelApiFailure(res?.data?.message)
          );
          Showtoast(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(GetUserManagementActions.userExcelApiFailure(err));
      });
  };
};

export const GetUserManagementActions = getUserExcelSlice.actions;
export default getUserExcelSlice.reducer;
