import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, ShowtoastSuccess } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";
import { getOrderHistoryDataAction } from "./getOrderHistory";
import secureLocalStorage from "react-secure-storage";

const initialApprovalState = {
  isLoading: false,
  successMsg: "",
  errMsg: "",
};

const orderPlanningApprovalSlice = createSlice({
  name: "Order Planning Approval Slice",
  initialState: initialApprovalState,
  reducers: {
    orderPlanningApprovalApiRequested(state) {
      state.isLoading = true;
      state.errMsg = "";
      state.successMsg = "";
    },
    orderPlanningApprovalApiSuccess(state, action) {
      state.isLoading = false;
      state.errMsg = "";
      state.successMsg = action.payload;
    },
    orderPlanningApprovalApiFailure(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload;
      state.successMsg = "";
    },
  },
});
function getTeamPath(loginResponse) {
  if (loginResponse?.teamName === "Planning Team") {
    return "planning";
  } else if (loginResponse?.teamName === "Finance Team") {
    return "finance";
  } else if(loginResponse?.teamName === "Marketing Team"){
    return "marketing";
  }
}

export const approveOrderPlanning = (data, navigate) => {
  return (dispatch) => {
    dispatch(orderPlanningApprovalActions.orderPlanningApprovalApiRequested());
    makeHttpRequest({
      method: "put",
      url: `/order/approval/${
        getTeamPath(JSON.parse(secureLocalStorage?.getItem("loginResponse")))}`,
      data: data,
      navigate,
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            orderPlanningApprovalActions.orderPlanningApprovalApiSuccess(
              res?.data?.message
            )
          );
          ShowtoastSuccess(res?.data?.message);
          dispatch(getOrderHistoryDataAction({ navigate, id: data?.orderId }));
        } else {
          dispatch(
            orderPlanningApprovalActions.orderPlanningApprovalApiFailure(
              res?.data?.message
            )
          );
          Showtoast(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          orderPlanningApprovalActions.orderPlanningApprovalApiFailure(
            "Something went wrong"
          )
        );
        Showtoast(err?.message);
      });
  };
};

export const orderPlanningApprovalActions = orderPlanningApprovalSlice.actions;
export default orderPlanningApprovalSlice.reducer;
