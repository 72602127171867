import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, ShowtoastSuccess } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";

const initialApprovalState = {
  isLoading: false,
  errMsg: "",
  orderRes: "",
};

const orderApprovalSlice = createSlice({
  name: "Order Approval Slice",
  initialState: initialApprovalState,
  reducers: {
    orderApprovalApiRequested(state) {
      state.isLoading = true;
      state.errMsg = "";
      state.orderRes = "";
    },
    orderApprovalApiSuccess(state, action) {
      state.isLoading = false;
      state.errMsg = "";
      state.orderRes = action.payload;
    },
    orderApprovalApiFailure(state, action) {
      state.isLoading = false;
      state.errMsg = action.payload;
      state.orderRes = "";
    },
  },
});

export const orderApprovalApiAction = (data, navigate) => {
  return (dispatch) => {
    dispatch(orderApprovalActions.orderApprovalApiRequested());

    makeHttpRequest({
      method: "put",
      url: `/order/approval/marketing`,
      data: data,
      navigate,
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            orderApprovalActions.orderApprovalApiSuccess(res?.data?.message)
          );
          localStorage.removeItem("path");
          ShowtoastSuccess(res?.data?.message);
          navigate("/order-management");
        } else {
          dispatch(
            orderApprovalActions.orderApprovalApiFailure(res?.data?.message)
          );
          Showtoast(res?.data?.message);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(orderApprovalActions.orderApprovalApiFailure(err?.message));
      });
  };
};

export const orderApprovalActions = orderApprovalSlice.actions;
export default orderApprovalSlice.reducer;
