import style from "./pageNotFound.module.css"
import * as images from "../../assets/index"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
      navigate("/page-not-found", { replace: true });
    }, [navigate]);
    return (
        <div className={style.center}>
           <div >
            <img
                src={images?.page_not_found}
                alt="Page Not Found"
                width={750}
                height={450}
            />
            </div>
        </div>
    );
    }

export default PageNotFound;