import {lazy,Suspense} from 'react'
import Loading from '../Loading/Loading'
const LazyLoading=(ComponentImport)=> {
    const Component=lazy(ComponentImport)
    return props=>(
        <Suspense fallback={<Loading/>}>
            <Component {...props}/>
        </Suspense>
    )
    
}

export default LazyLoading