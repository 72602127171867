const {createSlice} = require("@reduxjs/toolkit");
const {makeHttpRequest} = require("../apiService/apiService");
const {Showtoast} = require("../../helpers/utils");

const initialTaskState = {
    isLoading: false,
    isError: false,
    planningLeadListData: [],
    };
const getPlanningLeadListSlice = createSlice({
    name: "Get Planning Lead List",
    initialState: initialTaskState,
    reducers: {
        planningLeadListApiRequested(state) {
            state.isLoading = true;
            state.isError = false;
            state.planningLeadListData = [];
        },
        planningLeadListApiSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.planningLeadListData = action.payload;
        },
        planningLeadListApiFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.planningLeadListData = [];
        },
    },
});
export const getPlanningLeadListDataAction = ({ circleData, navigate }) => {
    return (dispatch) => {
        dispatch(GetPlanningLeadListActions.planningLeadListApiRequested());
        let url = `/planning-leads`;
        makeHttpRequest({ method: "post", url: url, data: circleData, navigate })
            .then((res) => {
                if (res?.data?.code === 200) {
                    dispatch(
                        GetPlanningLeadListActions.planningLeadListApiSuccess(res?.data?.data)
                    );
                } else {
                    dispatch(
                        GetPlanningLeadListActions.planningLeadListApiFailure(
                            res?.data?.message
                        )
                    );
                    Showtoast(res?.data?.message, "error");
                }
            })
            .catch((err) => {
                if (err?.isUnauthorized) {
                    return;
                }
                dispatch(
                    GetPlanningLeadListActions.planningLeadListApiFailure(
                        err?.response?.data?.error
                    )
                );
                Showtoast(err?.response?.data?.error, "error");
            });


    };
};
export const GetPlanningLeadListActions = getPlanningLeadListSlice.actions;
export default getPlanningLeadListSlice.reducer;