import { createSlice } from "@reduxjs/toolkit";
import { makeHttpRequest } from "../apiService/apiService";
import { encryptUsingAES256 } from "../../helpers/utils";

const initialUserManagementState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  userManagementData: [],
};

const getUserManagementSlice = createSlice({
  name: "Get User Management Data",
  initialState: initialUserManagementState,
  reducers: {
    userManagementApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.userManagementData = [];
    },
    userManagementApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.userManagementData = action.payload;
    },
    userManagementApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.userManagementData = [];
    },
  },
});

export const getUserManagementDataAction = ({
  searchData,
  page,
  filterData,
  navigate,
}) => {
  return (dispatch) => {
    dispatch(GetUserManagementActions.userManagementApiRequested());

    let url = `/users?page=${encryptUsingAES256(
      JSON.stringify(page)
    )}&limit=${encryptUsingAES256(JSON.stringify(10))}`;

    if (searchData) {
      url += `&searchKeyword=${encryptUsingAES256(searchData)}`;
    }

    if (filterData?.teamId) {
      url += `&teamId=${encryptUsingAES256(
        JSON.stringify(filterData?.teamId)
      )}`;
    }

    if (filterData?.roleId) {
      url += `&roleId=${encryptUsingAES256(
        JSON.stringify(filterData?.roleId)
      )}`;
    }

    if (filterData?.circleId) {
      url += `&circleId=${encryptUsingAES256(
        JSON.stringify(filterData?.circleId)
      )}`;
    }

    if (filterData?.statusId) {
      url += `&status=${encryptUsingAES256(
        filterData?.statusName === "Active" ? "ACTIVE" : "INACTIVE"
      )}`;
    }

    makeHttpRequest({ method: "get", url: url, navigate })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            GetUserManagementActions.userManagementApiSuccess(res?.data?.data)
          );
        } else {
          dispatch(
            GetUserManagementActions.userManagementApiFailure(
              res?.data?.message
            )
          );
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(GetUserManagementActions.userManagementApiFailure(err));
      });
  };
};

export const GetUserManagementActions = getUserManagementSlice.actions;
export default getUserManagementSlice.reducer;
