import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, generateRandomKey } from "../../helpers/utils";
import secureLocalStorage from "react-secure-storage";
import { makeHttpRequest } from "../apiService/apiService";
import { otpActionPost } from "../resetPassword/resetPassword";

const initialAuthState = {
  isAuthenticated: false,
  isLoading: false,
  isError: "",
  authRes: {},
  isRegistered: false,
  RegRes: null,
  isOTPSent: false,
  isOTPResend: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState: initialAuthState,
  reducers: {
    loginAPIRequested(state) {
      state.isLoading = true;
      state.isAuthenticated = false;
    },
    loginAPISuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.authRes = action.payload;
    },
    loginAPIFailure(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isError = action.payload;
    },
  },
});

export const userAuthentication = (data, setShow, navigate) => {
  const randomKey = generateRandomKey();
  return (dispatch) => {
    dispatch(authActions.loginAPIRequested());
    makeHttpRequest({
      method: "post",
      url: `/log-in`,
      data: data,
      headers: { Authorization: null },
      navigate,
      randomKey,
    })
      .then((response) => {
        if (response?.data?.status === "Success") {
          dispatch(authActions.loginAPISuccess(response?.data?.data));
          secureLocalStorage.setItem(
            "loginresponse",
            JSON.stringify(response?.data?.data)
          );

          sessionStorage?.removeItem("toast");

          setShow(true);
          dispatch(
            otpActionPost(
              { mobileNumber: data?.mobileNumber },
              setShow,
              navigate
            )
          );
        } else {
          if (response?.response?.status === 401) {
            return;
          }
          dispatch(authActions.loginAPIFailure(response?.data?.message));
          Showtoast(response?.data?.message);
        }
      })
      .catch((error) => {
        dispatch(authActions.loginAPIFailure(error));
      });
  };
};

export const authActions = loginSlice.actions;

export default loginSlice.reducer;
