import { createSlice } from "@reduxjs/toolkit";
import { Showtoast } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";
import ExcelFileDownload from "../../helpers/ExcelFileDownload";

const initialTaskState = {
  isLoading: false,
  isError: false,
  taskManagementData: [],
};

const getTaskManagementSlice = createSlice({
  name: "Get Order Management Data",
  initialState: initialTaskState,
  reducers: {
    taskManagementApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.taskManagementData = [];
    },
    taskManagementApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.taskManagementData = action.payload;
    },
    taskManagementApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.taskManagementData = [];
    },
  },
});

export const getTaskManagementDataAction = ({ data, navigate, isDownload }) => {
  console.log('data..',data);
  return (dispatch) => {
    if (!isDownload) {
      dispatch(GetTaskManagementActions.taskManagementApiRequested());
    }
    let url = `/order/self-order`;
    makeHttpRequest({ method: "post", url: url, data: data, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          if (isDownload) {
            ExcelFileDownload(res?.data.data?.records, "task");
          } else {
            dispatch(
              GetTaskManagementActions.taskManagementApiSuccess(res?.data?.data)
            );
          }
        } else {
          dispatch(
            GetTaskManagementActions.taskManagementApiFailure(
              res?.data?.message
            )
          );
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          GetTaskManagementActions.taskManagementApiFailure(
            err?.response?.data?.error
          )
        );
      });
  };
};

export const getAllTaskManagementDataAction = (
  data,
  navigate,
  isDownload = false
) => {
  return (dispatch) => {
    if (!isDownload) {
      dispatch(GetTaskManagementActions.taskManagementApiRequested());
    }
    let url = `/order/search`;
    makeHttpRequest({ method: "post", url: url, data: data, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          if (isDownload) {
            ExcelFileDownload(res?.data?.data, "task");
          } else {
            dispatch(
              GetTaskManagementActions.taskManagementApiSuccess(res?.data?.data)
            );
          }
        } else {
          dispatch(
            GetTaskManagementActions.taskManagementApiFailure(
              res?.data?.message
            )
          );
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          GetTaskManagementActions.taskManagementApiFailure(
            err?.response?.data?.error
          )
        );
      });
  };
};

export const GetTaskManagementActions = getTaskManagementSlice.actions;
export default getTaskManagementSlice.reducer;
