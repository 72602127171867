import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import {
  Showtoast,
  decryptUsingAES256,
  encryptUsingAES256,
  getBase64EncryptedRSAData,
  getRSADecryptedData,
} from "../../helpers/utils";

let baseUrl = process.env.REACT_APP_BACKEND_URL;

export async function makeHttpRequest({
  method,
  url,
  data = null,
  headers = {},
  navigate,
  skipEncryption = false,
  timeout = 60000,
}) {
  try {
    const config = buildRequestConfig(
      method,
      url,
      data,
      headers,
      navigate,
      skipEncryption,
      timeout
    );

    if (!skipEncryption) {
      encryptParams(config, data);
    }

    const response = await axios(config);

    const jsonResponse = handleResponse(response, navigate);
    return jsonResponse;
  } catch (error) {
    handleRequestError(error, navigate);
  }
}

function buildRequestConfig(
  method,
  url,
  data,
  headers,
  navigate,
  skipEncryption,
  timeout
) {
  const config = {
    method: method.toLowerCase(),
    url: `${baseUrl}${url}`,
    headers: buildRequestHeaders(headers, skipEncryption),
    timeout,
  };

  if (skipEncryption && data) {
    config.data = data;
  } else {
    encryptParams(config, data);
  }

  return config;
}

function buildRequestHeaders(headers, skipEncryption) {
  const defaultHeaders = {
    platform: "WEB",
    Authorization: `Bearer ${
      JSON.parse(secureLocalStorage.getItem("loginResponse"))?.bearerToken
    }`,
  };

  if (!skipEncryption) {
    defaultHeaders.param1 = getBase64EncryptedRSAData();
    defaultHeaders.param2 = getBase64EncryptedRSAData();
  }

  return {
    ...defaultHeaders,
    ...headers,
  };
}

function encryptParams(config, data) {
  if (data) {
    config.data = {
      param3: encryptUsingAES256(JSON.stringify(data)),
    };
  }
}

function handleResponse(response, navigate) {
  const { param1, param2, param3 } = response?.data || {};
  const decryptedParam1 = getRSADecryptedData(param1);
  const decryptedParam2 = getRSADecryptedData(param2);
  const decryptedResponse = decryptUsingAES256(
    param3,
    decryptedParam1,
    decryptedParam2
  );

  const jsonResponse = { data: JSON.parse(decryptedResponse) };
  if (jsonResponse?.data?.code === 401) {
    handleUnauthorized(navigate, jsonResponse);
  }
  return jsonResponse;
}

function handleUnauthorized(navigate, response) {
  if (sessionStorage?.getItem("toast") !== "true") {
    navigate("/");
    secureLocalStorage.clear();
    sessionStorage.setItem("toast", "true");
    Showtoast(response?.data?.message);
    throwUnauthorizedError();
  }

  throwUnauthorizedError();
}

function throwUnauthorizedError() {
  const unauthorizedError = new Error("Unauthorized");
  unauthorizedError.isUnauthorized = true;
  throw unauthorizedError;
}

function handleRequestError(error, navigate) {
  if (error.code === "ECONNABORTED") {
    navigate("/server-error");
    throwTimeoutError();
  }

  if (error.response && error.response.status >= 500) {
    navigate("/server-error");
    throwServerError();
  }

  if (error?.response?.status >= 400) {
    if (error?.response?.status === 401) {
      navigate("/");
      secureLocalStorage.clear();
      throwUnauthorizedError();
    }

    Showtoast(error?.message);
  }

  if (
    sessionStorage?.getItem("toast") &&
    sessionStorage?.getItem("toast") !== "true"
  ) {
    Showtoast("Something Went Wrong");
  }

  throw error;
}

function throwTimeoutError() {
  const timeoutError = new Error("Timeout");
  timeoutError.isTimeout = true;
  throw timeoutError;
}

function throwServerError() {
  const serverError = new Error("Server Error");
  serverError.isServerError = true;
  throw serverError;
}
