import {createSlice } from '@reduxjs/toolkit';
import {makeHttpRequest} from '../apiService/apiService';
import {Showtoast, ShowtoastSuccess} from '../../helpers/utils';

const initialTaskState = {
    isLoading: false,
    isError: false,
    assignSurveyorsData: ""
};

const assignSurveyorsSlice = createSlice({
    name: 'Assign Surveyors',
    initialState: initialTaskState,
    reducers: {
        assignSurveyorsApiRequested(state) {
            state.isLoading = true;
            state.isError = false;
            state.assignSurveyorsData = [];
        },
        assignSurveyorsApiSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.assignSurveyorsData = action.payload;
        },
        assignSurveyorsApiFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.assignSurveyorsData = [];
        }
    }
});

export const assignSurveyorsDataAction = ({assignSurveyor, navigate,path}) => {
    return (dispatch) => {
        dispatch(assignSurveyorsActions.assignSurveyorsApiRequested());
        let url = '/order/assign-surveyor';
        makeHttpRequest({method: 'post', url: url, data: assignSurveyor, navigate})
            .then((res) => {
                if (res?.data?.code === 200) {
                    dispatch(assignSurveyorsActions.assignSurveyorsApiSuccess(res?.data?.message));
                    ShowtoastSuccess(res?.data?.message)
                    navigate(path)
                } else {
                    dispatch(assignSurveyorsActions.assignSurveyorsApiFailure(res?.data?.message));
                    Showtoast(res?.data?.message, 'error');
                }
            })
            .catch((err) => {
                if (err?.isUnauthorized) {
                    return;
                }
                dispatch(assignSurveyorsActions.assignSurveyorsApiFailure(err?.response?.data?.error));
                Showtoast(err?.response?.data?.error, 'error');
            });
    };

};

export const assignSurveyorsActions = assignSurveyorsSlice.actions;
export default assignSurveyorsSlice.reducer;