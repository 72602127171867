import React from "react";
import style from "./inputs.module.css";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const CommonInput = ({
  label,
  type,
  name,
  value,
  onChange,
  setIconToggle,
  styles,
  placeholders,
  errMsg,
  onKeyDown,
  onKeyPress,
  icon,
  onBlur,
  errorBackground,
  labelShow = true,
  disabled,
}) => {
  let checkTf = function () {
    let charcode_max = 255;

    let tf = document.getElementById(name);

    for (let i = tf?.value?.length - 1; i >= 0; i--) {
      if (tf.value[i].charCodeAt(0) > charcode_max) {
        tf.value = tf.value.substr(0, i) + tf.value.substr(i + 1);
      }
    }
  };

  return (
    <div className={style.form_group} style={styles}>
      <TextField
        type={type}
        id={name}
        name={name}
        label={label}
        fullWidth
        disabled={disabled}
        className={style.input_box}
        variant="outlined"
        placeholder={placeholders}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        error={errMsg}
        helperText={errMsg}
        value={value}
        onInput={checkTf}
        FormHelperTextProps={{
          style: {
            margin: "0px 0px",
            padding: "0px 0px",
          },
        }}
        InputProps={{
          sx: { borderRadius: "8px" },
          endAdornment: icon && (
            <InputAdornment position="top">
              <IconButton
                onClick={() => {
                  setIconToggle?.((prev) => !prev);
                }}
                aria-label="toggle password visibility"
              >
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

CommonInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  styles: PropTypes.object,
  placeholders: PropTypes.string,
  errMsg: PropTypes.string,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  icon: PropTypes.node,
  labelShow: PropTypes.bool,
  errorBackground: PropTypes.string,
  disabled: PropTypes.bool,
  setIconToggle: PropTypes.func,
};

export default CommonInput;

export const CommonFileInput = ({
  label,
  type,
  name,
  value,
  onChange,
  onBlur,
  styles,
  placeholders,
}) => {
  return (
    <div className={style.form_group} style={styles}>
      <label htmlFor={name} className={style.input_label}>
        {label}
      </label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={style.input_file_box}
        onBlur={onBlur}
        accept=".kml"
        autoComplete="off"
        placeholder={placeholders}
        data-text="Attach Circle File"
      />
    </div>
  );
};

CommonFileInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  styles: PropTypes.object,
  placeholders: PropTypes.string,
};
