import { combineReducers } from "redux";
import authReducer from "./login/auth";
import circleListReducer from "./masterDataManagement/getCircleData";
import getUserManagementReducer from "./userManagement/getUserManagement";
import addNewCircleReducer from "./masterDataManagement/addNewCircle";
import getAllDropDownListReducer from "./getAllDropDownList/getAllDropDownList";
import addNewUserReducer from "./userManagement/newUserOnboarding";
import downloadKMLReducer from "./masterDataManagement/downloadKMLFile";
import getRoleManagementReducer from "./roleManagement/getRoleManagement";
import editRoleManagementReducer from "./roleManagement/editRoleManagement";
import resetPasswordReducer from "./resetPassword/resetPassword";
import getCircleKMLReducer from "./taskManagement/getCircleKMLFile";
import createOrderReducer from "./taskManagement/createOrder";
import getUserExcelDataReducer from "./userManagement/getUserExcelData";
import updateOrderReducer from "./taskManagement/updateOrder";
import orderApprovalReducer from "./taskManagement/orderApproval";
import getTaskManagementDataReducer from "./taskManagement/getTaskManagement";
import getOrderHistoryReducer from "./taskManagement/getOrderHistory";
import deleteUserReducer from "./userManagement/deleteUserData";
import getOrderFormHistoryReducer from "./taskManagement/getOrderFormHistory";
import getPlanningLeadListReducer from "./taskManagement/getPlanningLeadList";
import actionApprovalReducer from "./taskManagement/actionApproval";
import getSurveyorsListReducer from "./taskManagement/getSurveyorsList";
import assignSurveyorsReducer from "./taskManagement/assignSurveyors";
import getBOQFormDataReducer from "./taskManagement/getBOQFormData";
import updateBOQMasterApiAction from "./masterDataManagement/updateBOQMaster";
import updateBOQFormDataReducer from "./taskManagement/updateBOQFormData";
import getPolygonFormReducer from "./taskManagement/getPolygonForm";
import logOutReducer from "./logOut/logOut";
import bulkUploadReducer from "./userManagement/bulkUpload";

export default combineReducers({
  authReducer: authReducer,
  circleListReducer: circleListReducer,
  getUserManagementReducer: getUserManagementReducer,
  addNewCircleReducer: addNewCircleReducer,
  getAllDropDownListReducer: getAllDropDownListReducer,
  addNewUserReducer: addNewUserReducer,
  downloadKMLReducer: downloadKMLReducer,
  getRoleManagementReducer: getRoleManagementReducer,
  editRoleManagementReducer: editRoleManagementReducer,
  resetPasswordReducer: resetPasswordReducer,
  getCircleKMLReducer: getCircleKMLReducer,
  createOrderReducer: createOrderReducer,
  getUserExcelDataReducer: getUserExcelDataReducer,
  updateOrderReducer: updateOrderReducer,
  orderApprovalReducer: orderApprovalReducer,
  getTaskManagementDataReducer: getTaskManagementDataReducer,
  getOrderHistoryReducer: getOrderHistoryReducer,
  deleteUserReducer: deleteUserReducer,
  getOrderFormHistoryReducer: getOrderFormHistoryReducer,
  getPlanningLeadListReducer: getPlanningLeadListReducer,
  actionApprovalReducer: actionApprovalReducer,
  getSurveyorsListReducer: getSurveyorsListReducer,
  assignSurveyorsReducer: assignSurveyorsReducer,
  getBOQFormDataReducer: getBOQFormDataReducer,
  updateBOQMasterApiAction: updateBOQMasterApiAction,
  updateBOQFormDataReducer: updateBOQFormDataReducer,
  getPolygonFormReducer: getPolygonFormReducer,
  logOutReducer: logOutReducer,
  bulkUploadReducer: bulkUploadReducer,
});
