const { createSlice } = require("@reduxjs/toolkit");

const { makeHttpRequest } = require("../apiService/apiService");
const { Showtoast } = require("../../helpers/utils");

const initialTaskState = {
  isLoading: false,
  isError: false,
  surveyorsListData: [],
};

const getSurveyorsListSlice = createSlice({
  name: "Get Surveyors List",
  initialState: initialTaskState,
  reducers: {
    surveyorsListApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.surveyorsListData = [];
    },
    surveyorsListApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.surveyorsListData = action.payload;
    },
    surveyorsListApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.surveyorsListData = [];
    },
  },
});

export const getSurveyorsListDataAction = ({ circleData, navigate }) => {
  return (dispatch) => {
    dispatch(getSurveyorsListActions.surveyorsListApiRequested());
    let url = `/surveyors`;
    makeHttpRequest({ method: "post", url: url, data: circleData, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          dispatch(
            getSurveyorsListActions.surveyorsListApiSuccess(res?.data?.data)
          );
        } else {
          dispatch(
            getSurveyorsListActions.surveyorsListApiFailure(res?.data?.message)
          );
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          getSurveyorsListActions.surveyorsListApiFailure(
            err?.response?.data?.error
          )
        );
        Showtoast(err?.response?.data?.error, "error");
      });
  };
};

export const getSurveyorsListActions = getSurveyorsListSlice.actions;
export default getSurveyorsListSlice.reducer;
