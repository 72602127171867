export const login = {
  ftthApplicationLogin: "FTTH Application Login",
  mobile: "Mobile Number",
  password: "Password",
  enterYourMobileNumberPlaceholder: "Enter Your Mobile Number",
  enterYourPasswordPlaceholder: "Enter Your Password",
  forgotPassword: "Forgot password",
  login: "Login",
  or: "Or",
  loginWithCompanySSO: "Login with company SSO",
  forgotPasswordTextPara:
    " Please Enter Your Registered Mobile Number To Receive a Verification Code.",
  sendOTP: "Send OTP",
  OtpVerification: "OTP Verification",
  resendOtp: "Resend OTP",
  verifyProceed: "Verify & Proceed",
  passwordReset: `Password Reset`,
  newPassword: "New Password",
  confirmPassword: "Confirm Password",
  passwordMustMeetTheFollowingRequirements: `Password must meet the following requirements:`,
  atLeastOneNumber: "At least one number",
  atLeastOneCapitalLetter: "At least one capital letter",
  atLeastOneSmallLetter: "At least one small letter",
  atLeastOneSpecialCharacter: "At least one special character",
  beAtLeast8To15Characters: "Be at least 8 to 15 characters",
  submit: "Submit",
  cancel: "Cancel",
  userName: "User Name",
  OLMID: "OLM ID",
  emailID: "Email ID",
  circle: "Circle",
  enterYourNewPassword: "Enter Your New Password",
  enterYourConfirmPassword: "Enter Your Confirm Password",
};
export const masterData = {
  addNewCircle: "Add New Circle",
  KMLFileShouldContainOnlyOnePolygon:
    "KML file should contain only one polygon",
  fileTypeIsNotSupported: "File Type is not supported",
  masterDataManagement: "Master Data Management",
  circleFileManagement: "Circle File Management",
  masterBOQ: "Master BOQ",
  GISFileManagement: "GIS File Management",
  circleName: "Circle Name",
  enterCircleName: "Enter Circle Name",
  circleAbbreviation: "Circle Abbreviation",
  enterCircleAbbreviation: "Enter Circle Abbreviation",
  attachCircleFile: "Attach Circle File (KML File Only)",
  noPageMaster: "No Page Master",
  lastUpdatedOn: "Last Updated On",
  KMLFile: "KML File",
  action: "Action",
  downloadFile: "Download File",
  edit: "Edit",
  fatBox: "FAT BOX",
  ODF: "ODF",
  OLT: "OLT",
  fibreLength: "Fibre Length",
  thresholdHomepassCount: "Threshold Homepass Count",
  mobileNumber: "Mobile Number",
};

export const roleManagement = {
  roleManagementText: "Role Management",
  mmarketingTeam: "Marketing Team",
  role: "Role",
  access: "Access",
  feature: "Feature",
  noPageRole: "No Page Role",
  edit: "Edit",
  save: "Save",
  web: "WEB",
  both: "BOTH",
  mobile: "MOBILE",
};

export const taskManagement = {
  orderManagement: "Order Management",
  localityName: "Locality Name",
  enterLocalityName: "Enter Locality Name",
  pleaseEnterLocalityNamePlaceholder: "Please Enter Locality Name",
  saveAsDraft: "Save as Draft",
  doYouWantToSubmit: "Do you want to submit?",
  finalSubmit: "Final Submit",
  noPageTaskLocalityManagement: "No Page Task Locality Management",
  assignCirclePlanningLead: "Assign Circle Planning Lead",
  assign: "Assign",
  name: "Name",
  ongoingOrders: "Submitted Orders",
  assignSurveyor: "Assign Surveyor",
  search: "Search",
  BOQForm: "BOQ Form",
  homePassesCount: "Home Passes count",
  modelGeneratedCountLength: "Model Generated count/length",
  userGivenCountLength: "Tentative count / length",
  CostPerUnit: "Cost per unit (Piece/KM)",
  totalCost: "Total Cost",
  costPerHomePass: "Cost Per Home Pass",
  costPerUnitPrice: "Cost Per Unit (Price/KM)",
  fieldLabelName: "Field Label Name",
  userGivenCount: "Tentative Count",
  mobilityUserCount: "Mobility User Count",
  enterMobilityUserCount: "Enter Mobility User Count",
  B2BUserCount: "B2B User Count",
  enterB2BUserCount: "Enter B2B User Count",
  voWifiUserCount: "Vo-wifi User Count",
  enterVoWifiUserCount: "Enter Vo-wifi User Count",
  leadsCount: "Leads Count",
  enterLeadsCount: "Enter Leads Count",
  competitorOperatorName: "Competitor Operator Name",
  competitionHomePassCount: "Competition Home Pass Count",
  enterCompetitionHomePassCount: "Enter Competition Home Pass Count",
  orderID: "Order ID",
  createdOn: "Created On",
  createdBy: "Created By",
  details: "Details",
  status: "Status",
  historyStatus: "History Status",
  exportDataToExcel: "Export Data to Excel",
  createOrder: "Create Order",
  projectedHouseholdCount: "Projected Household Count",
  enterProjectedHouseholdCount: "Enter Projected Household Count",
  projectedHomePassCount: "Projected Home Pass Count",
  enterProjectedHomePassCount: "Enter Projected Home Pass Count",
  currentOrders: "Current Orders"
};

export const userManagement = {
  userManagement: "User Management",
  enterName: "Enter Name",
  team: "Team",
  areYouSureYouWantToMarkThisUserAs:
    "Are you sure you want to mark this user as",
  enterEmailId: "Enter Email Id",
};

export const orderHistoryActionCode = {
  BOQDone: "BQDONE",
  BOQUpdated: "BQUPDT",
  OrderCreated: "ORDCRT",
  OrderSubmitted: "ORDSUB",
  CeFHReject: "CNFHRJ",
  CiFHReject: "CFHRJC",
  CeFHCorrection: "CNFHCR",
  CiFHCorrection: "CFHSFC",
  OrderResubmited: "ORDRSB",
};

export const otherString = {
  placeTheOLTWhereYouWantToLocate: "Place the OLT where you want to locate.",
};
export const maxThresholdDistance = 50;
