import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, encryptUsingAES256 } from "../../helpers/utils";
import { makeHttpRequest } from "../apiService/apiService";

const initialOrderFormHistory = {
  isLoading: false,
  isError: false,
  orderHistoryData: [],
};

const getOrderFormHistorySlice = createSlice({
  name: "Get Order Form History Data",
  initialState: initialOrderFormHistory,
  reducers: {
    orderFormHistoryApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.orderHistoryData = [];
    },
    orderFormHistoryApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.orderHistoryData = action.payload;
    },
    orderFormHistoryApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.orderHistoryData = [];
    },
    resetOrderFormHistory(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.orderHistoryData = [];
    },
  },
});

export const getOrderFormHistoryDataAction = ({ navigate, id }) => {
  return (dispatch) => {
    dispatch(GetOrderFormHistoryDataAction.orderFormHistoryApiRequested());
    let url = `/order/form?orderId=${encryptUsingAES256(JSON.stringify(id))}`;
    makeHttpRequest({ method: "get", url: url, navigate })
      .then((res) => {
        if (res?.data?.code === 200) {
          dispatch(
            GetOrderFormHistoryDataAction.orderFormHistoryApiSuccess(res?.data?.data)
          );
        } else {
          dispatch(
            GetOrderFormHistoryDataAction.orderFormHistoryApiFailure(res?.data?.message)
          );
          Showtoast(res?.data?.message, "error");
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          GetOrderFormHistoryDataAction.orderFormHistoryApiFailure(
            err?.response?.data?.message
          )
        );
      });
  };
};

export const GetOrderFormHistoryDataAction = getOrderFormHistorySlice.actions;
export default getOrderFormHistorySlice.reducer;
