import { createSlice } from "@reduxjs/toolkit";
import {
  Showtoast,
  ShowtoastSuccess,
  encryptUsingAES256,
} from "../../helpers/utils";
import { getUserManagementDataAction } from "./getUserManagement";
import { makeHttpRequest } from "../apiService/apiService";

const initialUserState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const deleteUserSlice = createSlice({
  name: "Delete User",
  initialState: initialUserState,
  reducers: {
    deleteUserApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    },
    deleteUserApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    },
    deleteUserApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
    },
  },
});

export const deleteUserApiAction = (
  mobileNumber,
  setShow,
  page,
  filterData,
  searchData,
  navigate
) => {
  return (dispatch) => {
    dispatch(deleteUserActions.deleteUserApiRequested());

    makeHttpRequest({
      method: "delete",
      url: `/delete-user?mobileNumber=${encryptUsingAES256(mobileNumber)}`,
      navigate,
    })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setShow(false);
          dispatch(deleteUserActions.deleteUserApiSuccess(res?.data?.data));
          dispatch(
            getUserManagementDataAction({
              searchData: searchData,
              page: page,
              filterData: filterData,
              navigate: navigate,
            })
          );
          ShowtoastSuccess(res?.data?.message);
        } else {
          Showtoast(res?.data?.message);
          dispatch(deleteUserActions.deleteUserApiFailure(res?.data?.message));
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(deleteUserActions.deleteUserApiFailure(err));
      });
  };
};

export const deleteUserActions = deleteUserSlice.actions;
export default deleteUserSlice.reducer;
