import React from "react";
import style from "./serverError.module.css";
import * as images from "../../assets/index";
import CommonButton from "../CommonButton";
import { useNavigate } from "react-router-dom";
const ServerError = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  return (
    <div id={style.server_error}>
      <div className={style.logo}>
        <img
          alt="airtelLogo"
          src={images?.aiterlLogo}
          onClick={handleBack}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleBack();
            }
          }}
        />
      </div>
      <div className={style.center}>
        <div>
          <img
            src={images?.error}
            alt="Page Not Found"
            width={783}
            height={487}
          />
        </div>
        <h2>Internal Server Error</h2>
        <div className="d-flex justify-content-center">
          <CommonButton
            label="Back to home"
            type="filled"
            height={true}
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </div>
  );
};

export default ServerError;
