import { makeHttpRequest } from "../apiService/apiService";
import { createSlice } from "@reduxjs/toolkit";
import { Showtoast, ShowtoastSuccess } from "../../helpers/utils";
import { getCircleDataAction } from "./getCircleData";

const initialTaskState = {
  isLoading: false,
  isError: false,
  updateBoq: "",
};
const updateBOQMasterSlice = createSlice({
  name: "Update BOQ Master",
  initialState: initialTaskState,
  reducers: {
    updateBOQMasterApiRequested(state) {
      state.isLoading = true;
      state.isError = false;
      state.updateBoq = "";
    },
    updateBOQMasterApiSuccess(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.updateBoq = action.payload;
    },
    updateBOQMasterApiFailure(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.updateBoq = "";
    },
  },
});

export const updateBOQMasterApiAction = (data, navigate, setShowTwo, activeTab, circleData) => {
  return (dispatch) => {
    dispatch(updateBOQMasterActions.updateBOQMasterApiRequested());
    makeHttpRequest({ method: "put", url: "/boq-master", data, navigate })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch(
            updateBOQMasterActions.updateBOQMasterApiSuccess(res?.data?.message)
          );
          dispatch(
            getCircleDataAction(
              circleData,
              navigate,
              activeTab,
              false,
            )
          );
          ShowtoastSuccess(res?.data?.message);
          setShowTwo(false);
        } else {
          dispatch(
            updateBOQMasterActions.updateBOQMasterApiFailure(res?.data?.message)
          );
          Showtoast(res?.data?.message, "error");
          setShowTwo(false);
        }
      })
      .catch((err) => {
        if (err?.isUnauthorized) {
          return;
        }
        dispatch(
          updateBOQMasterActions.updateBOQMasterApiFailure(
            err?.response?.data?.error
          )
        );
        setShowTwo(false);
      });
  };
};

export const updateBOQMasterActions = updateBOQMasterSlice.actions;

export default updateBOQMasterSlice.reducer;
